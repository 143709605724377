import React from 'react'
import { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import axios from 'axios'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import CardWrapper from '@mui/material/Card'
import Wrapper from '@mui/material/Container'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import Error from './Error';
const API_BASE_URL = "https://servicios-sel-otec.cl/"
const matchDownSM = true

const VerifyCertificate = () => {
    const [success, setSuccess] = useState(false);
    const [dataCertificate, setDataCertificate] = useState({});
    const [isError, setIsError] = useState(false);
    const [error, setError] = useState({});
    const { correlativo_certificado } = useParams()

    useEffect(() => {
        getVerificateCertificate(correlativo_certificado).then(({ data }) => {
            if (data?.success) {
                setDataCertificate(data);
                setSuccess(data?.success);
            } else {
                setError({ data: { code: "certificate_not_found", message: "No se encontró ningún certificado válido.", data: { status: "404" } } });
                setIsError(true);
            }
        });
    }, [correlativo_certificado, setIsError]);

    const { fecha_culminacion, nombre_curso, codigo_certificado, nombres_estudiante, apellidos_estudiante, user_name } = dataCertificate;

    return isError ? <Error error={error}></Error> : (
        <Wrapper>
            <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh' }}>
                <Grid item xs={12}>
                    <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}>
                        <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
                            <CardWrapper>
                                <Grid container spacing={2} alignItems="center" justifyContent="center">
                                    <Grid item sx={{ mb: 3 }}>
                                        <Link to="login">
                                      
                                        </Link>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid                                        
                                        >
                                            <Grid item>
                                                {success ? (<Stack alignItems="center" justifyContent="center" spacing={1}>
                                                   
                                                        {"El certificado es válido!"}
                                            
                                                    <Paper>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12}>
                                                                
                                                                    {nombre_curso}
                                                            
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                               
                                                                    Certificado: Reg. N° {codigo_certificado}
                                                               
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                               
                                                                    Nombre del estudiante: {nombres_estudiante} {apellidos_estudiante}
                                                           
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                               
                                                                    Fecha de culminación: {fecha_culminacion}
                                                               
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                
                                                                    Empresa: {user_name}
                                                          
                                                            </Grid>
                                                        </Grid>
                                                    </Paper>
                                                </Stack>) : (
                                                    <>
                                                    
                                                        <br /><br />
                                                        Cargando información...
                                                    </>)}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CardWrapper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Wrapper>
    );
};

export default VerifyCertificate;

export const getVerificateCertificate = async (correlativo_certificado) => {
    const options = {
        method: 'get',
        url: `${API_BASE_URL}wp-json/operator/v1/certificates/${correlativo_certificado}`,
        headers: {
            'Content-Type': 'application/json'
        }
    };

    return await axios(options)
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return error?.response;
        });;
};
