import "./App.css";
// Importing the React Router Dom
import {
  BrowserRouter as Router,
  Routes,
  Route,
  NavLink,
} from "react-router-dom";
import Home from "./Components/Home";
import Certificado from "./Components/Certificado";
import Error from "./Components/Error";
/*import Contact from "./Components/Contact";

import Profile from "./Components/Profile";
import Friends from "./Components/Friends";
import Request from "./Components/Request";
import Stranger from "./Components/Stranger";
import ContactSearch from "./Components/ContactSearch";
import User from "./Components/User";
import UserDetails from "./Components/UserDetails";*/

function App() {
  const navigationActive = ({ isActive }) => {
    return {
      color: isActive ? "white" : "black",
      textDecoration: "none",
    };
  };
  return (
    <Router>
      {/* Creating the Navigation Link to go from one route to another using the NavLink  */}
     
      <Routes>
       
        <Route path="/certificado/:correlativo_certificado" element={<Certificado />} />
        <Route path="*" element={<Error />} />
      </Routes>
    </Router>
  );
}

export default App;